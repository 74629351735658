import {
  selectConfigItemByType,
  selectConfiguredVoiceLines,
  selectLineItemByType,
  selectIsConfigItemSelfInstallCompatible
} from './configuration'
import {
  officeLineItemTypes,
  voiceLineItemTypes,
  configTypes,
  internetConfigurationItems,
  installLineItemTypes,
  videoLineItemTypes,
  videoDwellTypes,
  selectedInternetSpeedTypes,
  internetLineItemTypes
} from '../constants/configuration'
import { setVoiceLineAddOns } from '../utils/cart'
import { selectActivePageId } from './router'
import { orderRouteIds, orderRoutes } from '../constants/navigation'
import { getSelectedConfigItem } from '../utils/configuration'

export function selectSmartOfficeInstallTotal(state) {
  const installConfigItem = selectConfigItemByType(
    officeLineItemTypes.cameraInstall
  )(state)
  return installConfigItem?.oneTimeFinalPrice || 0
}

export function selectTotalOneTimeCharges({ cart } = {}) {
  // TODO: This might need to change when smartoffice is fully implemented
  return cart?.summary?.totalOneTimeCharges || 0
}

export function selectSmartOfficePaymentPlan(state) {
  const activePageId = selectActivePageId(state)

  if (activePageId === orderRouteIds.configure) {
    return state.configuration?.offer?.smartOfficeInstallPlan
  }

  return state.cart?.details?.smartOfficeInstallPlan
}

export function selectConfiguredCart(state) {
  const { linesOfBusiness, ...cart } = state.cart.summary
  const { opportunityId, fxbuyflowSessionId } = state.session
  const { voice, offer, tv } = state.configuration
  const updatedLinesOfBusiness = { ...linesOfBusiness }

  // Delete unneeded properties
  delete cart.categories
  delete cart.changedCartItems

  // changing tv primary_tv_equip to tv_equip as BE doesn't recognize
  // TODO: analyze why we were doing this way to update in redux state.
  if (tv?.PRIMARY_TV_EQUIP) {
    const tvConfig = {
      ...tv.PRIMARY_TV_EQUIP,
      type: videoLineItemTypes.tvEquipment,
      configurationItems: [
        ...Object.keys(tv.PRIMARY_TV_EQUIP.configurationItems).map(x => ({
          ...tv.PRIMARY_TV_EQUIP.configurationItems[x],
          lineItemType: videoLineItemTypes.tvEquipment
        }))
      ]
    }

    const updatedTvEquip = updatedLinesOfBusiness.tv.items.device.map(x =>
      x.type === videoLineItemTypes.primaryOutlet ? { ...tvConfig } : x
    )

    updatedLinesOfBusiness.tv.items.device = [...updatedTvEquip]
  }

  if (voice?.configuredItems?.configuredLines) {
    const configuredVoiceLines = selectConfiguredVoiceLines(state)
    const voiceMailConfig = selectConfigItemByType(
      voiceLineItemTypes.voiceMail
    )(state)
    const huntGroupConfig = selectConfigItemByType(
      voiceLineItemTypes.huntGroup
    )(state)
    const directoryListing = selectConfigItemByType(
      voiceLineItemTypes.directoryListing
    )(state)
    const activationFee = selectConfigItemByType(
      voiceLineItemTypes.activationFee
    )(state)
    const tollFreeActivationFee = selectConfigItemByType(
      voiceLineItemTypes.tollFreeActivationFee
    )(state)

    updatedLinesOfBusiness.voice = {
      ...linesOfBusiness.voice,
      configuredItems: configuredVoiceLines
        ? {
            ...voice.configuredItems,
            ...setVoiceLineAddOns(configuredVoiceLines, {
              [voiceLineItemTypes.voiceMail]: voiceMailConfig,
              [voiceLineItemTypes.huntGroup]: huntGroupConfig,
              [voiceLineItemTypes.directoryListing]: directoryListing,
              [voiceLineItemTypes.activationFee]: activationFee,
              [voiceLineItemTypes.tollFreeActivationFee]: tollFreeActivationFee
            })
          }
        : linesOfBusiness[configTypes.voice].configuredItems
    }
  }

  const isRevertFdxConfig = selectShowFdxConfigRevertError(state)

  return {
    ...cart,
    fxbuyflowSessionId,
    offer,
    opportunityId,
    cartItems: Object.values(updatedLinesOfBusiness),
    isRevertFdxConfig
  }
}

export function selectDisconnectDetails(state) {
  return state.cart?.summary?.lobDisconnectDetails || {}
}

export function selectProductCompatibility(lob, id) {
  return state => {
    const lineOfBusiness = state.cart?.summary?.linesOfBusiness[lob]
    return lineOfBusiness?.productCompatibility?.[id] || []
  }
}

export function selectIsEligibleForSelfInstall(state) {
  const { changedCartItems = [] } = state.cart.summary || {}

  if (!changedCartItems.length) {
    return true
  }

  const compatibleIds = selectProductCompatibility(
    configTypes.internet,
    internetConfigurationItems.selfInstallStandard
  )(state)

  return state.cart.summary.changedCartItems.every(item =>
    compatibleIds.includes(item.id)
  )
}

export function selectIsSIKSelected(state) {
  const lineItem = selectLineItemByType(installLineItemTypes.selfInstall)(state)
  return !!(lineItem && getSelectedConfigItem(lineItem))
}

export function selectShouldConfirmSIKRemoval(selectedItem) {
  return state => {
    const selectedSIK = selectIsSIKSelected(state)
    const sikCompatible = selectIsConfigItemSelfInstallCompatible(selectedItem)(
      state
    )

    if (selectedSIK && !sikCompatible) {
      return true
    }
    return false
  }
}

export function selectVideoDwellType(state) {
  return state.cart?.summary?.dwellTypeView?.toLowerCase() || ''
}

export function selectShowDwellTypeError(state) {
  const publicViewItem = selectConfigItemByType(videoLineItemTypes.publicView)(
    state
  )
  const configDwellType = selectVideoDwellType(state)

  // early return if no tv/public view config item
  if (!publicViewItem) {
    return false
  }

  const showDwellTypeMessage =
    (!publicViewItem.isSelected &&
      configDwellType === videoDwellTypes.public) ||
    (publicViewItem.isSelected && configDwellType === videoDwellTypes.private)

  if (showDwellTypeMessage) {
    return true
  }

  return false
}

export function selectIsFdxConfigReverted(state) {
  return state.cart?.summary?.isRevertFdxConfig
}

export function selectedInternetSpeedEPON(state) {
  const { selectedSpeedType = '' } = state?.cart?.summary || {}
  return selectedSpeedType?.toUpperCase() === selectedInternetSpeedTypes.epon
}

export function selectShowFdxConfigRevertError(state) {
  const selectedInternetEquipment = selectConfigItemByType(
    internetLineItemTypes.internetEquipment
  )(state)
  const { selectedSpeedType = '' } = state?.cart.summary || {}
  const { downgradedGenesisSpeed = '' } = state.configuration.offer

  const { voice, tv } = state.configuration || {}
  let isRevertFdxConfigFlag = false
  if (!Object.values(selectedInternetSpeedTypes).includes(selectedSpeedType)) {
    return false
  }
  if (selectedSpeedType === selectedInternetSpeedTypes.fdx) {
    let voiceLinesQuantity = 0
    let totalVideoBoxQuantity = 0

    if (voice?.configuredItems?.configuredLines) {
      const { voiceLines } = selectConfiguredVoiceLines(state)
      voiceLinesQuantity = voiceLines.length
    }

    if (tv) {
      const primaryOutletConfigItem = selectConfigItemByType(
        videoLineItemTypes.primaryOutlet
      )(state)
      const additionalOutletConfigItem = selectConfigItemByType(
        videoLineItemTypes.additionalOutlets
      )(state)

      totalVideoBoxQuantity =
        (primaryOutletConfigItem?.quantity ?? 0) +
        (additionalOutletConfigItem?.quantity ?? 0)
    }
    isRevertFdxConfigFlag = voiceLinesQuantity > 8 ||
      totalVideoBoxQuantity > 15 
  }

  if (downgradedGenesisSpeed) {
    isRevertFdxConfigFlag = 
      selectedInternetEquipment?.id ===
        internetConfigurationItems.customerOwnedEquipment
    
  }
  return isRevertFdxConfigFlag
}

export function selectIsOfferHardExpired(state) {
  const pageId = selectActivePageId(state)

  const {
    documents: documentsRoute,
    configure: configureRoute,
    submit: submitRoute
  } = orderRoutes

  const showOfferExpireRoutes = [
    configureRoute.id,
    documentsRoute.id,
    submitRoute.id
  ]

  const isOfferHardExpiredQuoteOrDetails =
    state.cart?.details?.isOfferHardExpired !== undefined
      ? state.cart?.details?.isOfferHardExpired
      : state.cart?.quote?.isOfferHardExpired

  const isOfferHardExpiredSummary =
    state.cart?.summary?.offer?.isOfferHardExpired !== undefined
      ? state.cart?.summary?.offer?.isOfferHardExpired
      : isOfferHardExpiredQuoteOrDetails

  return (isOfferHardExpiredSummary && showOfferExpireRoutes.includes(pageId))
}
